import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";

import Menu from "./component/Menu";
import TaskButton from "../../components/mobile/task/TaskButton";
import Modal, { IModal } from "../../components/modal/Modal";
import CustomTable from "../../components/table/customTable/CustomTable";
import useTable from "../../components/table/customTable/hook/useTable";
import Pagination from "../../components/table/customTable/pagination/Pagination";
import StatusTable from "../../components/table/statusTable/statusTable";
import { clientServicesContext } from "../../context/clientServicesContext";
import { taskManagementContext } from "../../context/TaskManagementContext";
import useWindowDimensions from "../../hook/useWindowDimensions";

// Style
import "../../static/scss/task-management.scss";

import { TASKS_UNAVAILABLE_TYPES, serviceUsableState } from "../../recoil/services.atom";

const TaskManager = () => {
    const taskManagerStore = useContext(taskManagementContext);
    const { initTable } = useTable();
    const [modalState, setModal] = useState<IModal>({
        show: false,
        showCancel: false,
        onConfirm: () => setModal({ ...modalState, show: false })
    });
    const { t } = useTranslation();
    const { push } = useHistory();
    const {
        getData: getClientServices,
        data: clientServices,
        error: clientServicesError
    } = useContext(clientServicesContext);
    const [show, setShow] = useState(false);
    const { width } = useWindowDimensions();
    const canUseTask = useRecoilValue(serviceUsableState);
    const [loading, setIsLoading] = useState({ tableLoading: taskManagerStore.isLoading });

    useEffect(() => {
        setIsLoading({ ...loading, tableLoading: taskManagerStore.isLoading });
        if (taskManagerStore.historyCallsTable) {
            initTable(taskManagerStore.historyCallsTable);
        }
    }, [taskManagerStore, clientServices]);

    useEffect(() => {
        (async () => {
            if (!clientServices && !clientServicesError) {
                await getClientServices();
            }
        })();
    }, [clientServices, clientServicesError]);

    const handleModal = () => {
        if (canUseTask.reason === TASKS_UNAVAILABLE_TYPES.INACTIVE_SERVICES) {
            setModal((prev) => ({
                ...prev,
                show: true,
                title: t("error.servicesDeactivated-title"),
                message: t("error.servicesDeactivated-message")
            }));
        }
        if (canUseTask.reason === TASKS_UNAVAILABLE_TYPES.CARD_EXPIRED) {
            setModal((prev) => ({
                ...prev,
                show: true,
                title: t("error.cardExpired-title"),
                message: t("error.cardExpired-message")
            }));
        }
    };

    const handleButtonService = (goTo: string) => {
        if (loading.tableLoading) return;
        if (!canUseTask.status) {
            handleModal();
        } else {
            setShow(!show);
            push(goTo);
        }
    };

    return (
        <div className="task-manager__container d-flex flex-column">
            <Menu handleButtonService={handleButtonService} canUseNavigation={canUseTask.status} />
            <div
                className="middle-container"
                style={{ marginTop: `${width < 992 ? "0" : "70px"}` }}
            >
                <CustomTable
                    customClassname={canUseTask.status === false ? "table-disabled" : ""}
                    handleSort={taskManagerStore.handleSortTable}
                    sortState={taskManagerStore.sortState}
                    loading={loading.tableLoading}
                />
                <Pagination
                    handlePage={taskManagerStore.handlePage}
                    paginationMeta={{
                        page: taskManagerStore.paginationMeta.page,
                        pagesCount: taskManagerStore.paginationMeta.pageCount
                    }}
                    handleElementsPerPage={taskManagerStore.handleElementsPerPage}
                />
            </div>
            <Modal {...modalState} />
            <TaskButton />
        </div>
    );
};

export default TaskManager;
