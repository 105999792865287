import { Trans } from "react-i18next";

import { ReactComponent as ListRow } from "../../../static/icons/icon_list_row.svg";
import "./statusTable.scss";

interface IStatusTable {
    page: string;
    status: "loading" | "empty";
}

const StatusTable: React.FC<IStatusTable> = ({ page, status }) => {
    return (
        <tr
            id="status-table"
            style={{
                width: "100%"
            }}
        >
            <td colSpan={6}>
                <ListRow />
                <div>
                    <h2>
                        <Trans i18nKey={`${page}.table.${status}.title`} />
                    </h2>
                    <p>
                        <Trans i18nKey={`${page}.table.${status}.text`} />
                    </p>
                </div>
            </td>
        </tr>
    );
};

export default StatusTable;
