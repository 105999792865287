import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";

import "./style/customCalendar.scss";
import { customCalendarContext } from "../../context/calendarContext";
import { convertSize } from "../../services/tools";
import { ReactComponent as ArrowSVG } from "../../static/icons/icon-arrow-calendar.svg";
import CustomButton from "../button/button";

interface ICustomCalendar {
    serviceCalendarName?: string;
    onChangeSelect?: any;
    isSelect?: boolean;
    optionSelected?: any;
    selectOptions?: any[];
}

const CustomCalendar: React.FC<ICustomCalendar> = () => {
    const { t } = useTranslation();
    const { handleChangeYear, show, handleShow, selectOptions, globalState } =
        useContext(customCalendarContext);
    if (!show) {
        return null;
    }
    const dataType = selectOptions.value;

    const totalDataPerKey = (key: any) => {
        let cpt = 0;
        if (globalState?.elemToDisplay?.months) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            for (const elem of globalState?.elemToDisplay?.months) {
                cpt += elem[key];
            }
        }
        return dataType === "consumptionPerYear" ? `${convertSize(cpt)}` : cpt;
    };

    const getData = (elem: any) => {
        if (dataType === "consumptionPerYear") {
            return convertSize(elem);
        } else {
            return elem;
        }
    };

    const onClose = () => handleShow(false);

    return (
        // TODO fix
        //@ts-ignore
        <SweetAlert
            show={show}
            style={{ width: "65%", borderRadius: "3%" }} //overwrite modal style
            closeOnClickOutside={true}
            title=""
            showConfirm={false}
            onConfirm={() => null}
            onCancel={onClose}
            customClass="modal-calendar-container"
        >
            <div className="px-2 py-2 h-100 w-100 modal-calendar-container d-flex flex-column">
                <div className="calendar-header w-100 d-flex justify-content-between align-items-center">
                    <h1 className="mb-0">
                        {dataType === "apiCallsPerYear"
                            ? t("dashboard.service-usage")
                            : t("dashboard.service-consumption")}
                    </h1>
                    <div className="d-flex align-items-center">
                        <ArrowSVG
                            fill="#47dc95"
                            height={20}
                            width={20}
                            className="arrow-year mr-2"
                            onClick={() => handleChangeYear("-")}
                        />
                        <h2 className="mb-0">{globalState.elemToDisplay?.year}</h2>
                        <ArrowSVG
                            fill="#47dc95"
                            height={20}
                            width={20}
                            className="arrow-year arrow-reversed ml-2"
                            onClick={() => handleChangeYear("+")}
                        />
                    </div>
                </div>
                <div className="total-conso d-flex justify-content-between align-items-center">
                    {dataType === "identityPerYear" ? (
                        <>
                            <div className="d-flex">
                                <label className="mr-2">Models:</label>
                                <span>{totalDataPerKey("model")}</span>
                            </div>
                            <div className="d-flex">
                                <label className="mr-2">Vectors:</label>
                                <span>{totalDataPerKey("vectors")}</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="d-flex">
                                <label className="mr-2">Total:</label>
                                <span>{totalDataPerKey("total")}</span>
                            </div>
                            <div className="d-flex">
                                <label className="mr-2">Picture:</label>
                                <span>{totalDataPerKey("image")}</span>
                            </div>
                            <div className="d-flex">
                                <label className="mr-2">Video:</label>
                                <span>{totalDataPerKey("video")}</span>
                            </div>
                        </>
                    )}
                </div>
                <div className="calendar-container mb-2">
                    {globalState.elemToDisplay?.months?.map((elem: any, index: number) => (
                        <div className="month-container" key={index}>
                            <div
                                className={`inner-month w-100 h-100 flex-column align-items-center text-bold ${
                                    elem.month === moment().format("YYYY-MM") && "active"
                                }`}
                            >
                                <div className="text-bold">{moment(elem.month).format("MMMM")}</div>
                                <div className="h-100 py-2 mb-2 d-flex flex-column align-items-center justify-content-center">
                                    {dataType !== "identityPerYear" && (
                                        <div className="d-flex justify-content-between w-100 inner-data align-items-center">
                                            <label
                                                className={`${
                                                    elem.month === moment().format("YYYY-MM") &&
                                                    "color-white"
                                                }`}
                                            >
                                                Total:{" "}
                                            </label>
                                            <span>
                                                {dataType === "consumptionPerYear"
                                                    ? `${convertSize(elem.total)}`
                                                    : elem.total}
                                            </span>
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-between w-100 inner-data align-items-center">
                                        <label
                                            className={`${elem.month === moment().format("YYYY-MM") && "color-white"}`}
                                        >
                                            {dataType !== "identityPerYear" ? "Image:" : "Model:"}
                                        </label>
                                        <span>
                                            {getData(
                                                dataType === "identityPerYear"
                                                    ? elem.model
                                                    : elem.image
                                            )}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between w-100 inner-data align-items-center">
                                        <label
                                            className={`${elem.month === moment().format("YYYY-MM") && "color-white"}`}
                                        >
                                            {dataType !== "identityPerYear" ? "Video:" : "Vector:"}
                                        </label>
                                        <span>
                                            {getData(
                                                dataType === "identityPerYear"
                                                    ? elem.vectors
                                                    : elem.video
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <CustomButton buttonText={"close"} classNameType={"main"} onClick={onClose} />
                </div>
            </div>
        </SweetAlert>
    );
};

export default CustomCalendar;
