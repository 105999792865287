import React, { createContext, useContext, useState, useEffect } from "react";

import moment from "moment";

import { useAxios } from "../services/hook/requestsService";

import { formatCalendarData, listApiServices } from "../pages/servicesManagement/helper/formatData";

interface ICalendarContext {
    handleChangeYear: Function;
    setSelectOptions: Function;
    selectOptions: any;
    show?: boolean;
    handleShow: Function;
    handleSelect: any;
    options: any[];
    globalState: any;
}

const customCalendarContext = createContext({} as ICalendarContext);

const CustomCalendarProvider: React.FC<any> = ({ children }) => {
    const [options, setOptions] = useState([
        { label: "Consumption", value: "consumptionPerYear" },
        { label: "API Call", value: "apiCallsPerYear" },
        { label: "Face recognition", value: "identityPerYear" }
    ]);
    const [selectOptions, setSelectOptions] = useState<any>(options[0]);
    const [show, setShow] = useState(false);
    const [globalState, setGlobalState] = useState<any>({
        apiCallsPerYear: null,
        consumptionPerYear: null,
        identityPerYear: null,
        elemToDisplay: [],
        isLoaded: false,
        searchKey: null
    });
    const axiosActions = useAxios();

    const [servicesManagementState, setServicesManagementState] = useState({
        isLoading: true,
        consumptionState: {
            data: {
                totalCounts: [],
                totalCountsPerPeriod: []
            }
        }
    });

    // Récupération des données au lieu de via le context
    const fetchData = async () => {
        try {
            if (servicesManagementState.isLoading) {
                const { data } = await axiosActions.getData(null, "history-call/consumption");
                setServicesManagementState({
                    isLoading: false,
                    consumptionState: {
                        data
                    }
                });
            }
        } catch (e) {
            setServicesManagementState({
                isLoading: true,
                consumptionState: {
                    data: {
                        totalCounts: [],
                        totalCountsPerPeriod: []
                    }
                }
            });
        }
    };

    useEffect(() => {
        fetchData();

        if (
            !servicesManagementState.isLoading &&
            servicesManagementState?.consumptionState?.data?.totalCountsPerPeriod
        ) {
            setGlobalState({
                ...globalState,
                isLoaded: true,
                ...formatCalendarData(
                    servicesManagementState.consumptionState.data.totalCountsPerPeriod
                )
            });
        }
        return () => {
            setGlobalState({
                apiCallsPerYear: null,
                consumptionPerYear: null,
                identityPerYear: null,
                elemToDisplay: [],
                isLoaded: false,
                searchKey: null
            });
        };
    }, [
        servicesManagementState.isLoading,
        servicesManagementState?.consumptionState?.data?.totalCountsPerPeriod
    ]);

    //when user click on arrow year
    const handleChangeYear = (action: string) => {
        let newYearElem = globalState.elemToDisplay;
        if (action === "+") {
            newYearElem =
                globalState[selectOptions.value].find(
                    (yearElem: any) => yearElem.year === newYearElem.year + 1
                ) || newYearElem;
        } else {
            newYearElem =
                globalState[selectOptions.value].find(
                    (yearElem: any) => yearElem.year === newYearElem.year - 1
                ) || newYearElem;
        }
        setGlobalState({
            ...globalState,
            elemToDisplay: newYearElem
        });
    };

    //open / close calendar
    const handleShow = (
        value: boolean,
        optionToSelect = "apiCallsPerYear",
        searchKey?: string,
        typeOfCall?: string
    ) => {
        //open
        setShow(value);

        let dataBySearchKey = null;
        //si on est sur identity et que le call est upsert OU qu'on est sur le calendrier general on ajout face recognition dans le select
        if ((searchKey === "identity" && typeOfCall === "UPSERT") || !searchKey) {
            setOptions([
                { label: "Consumption", value: "consumptionPerYear" },
                { label: "API Call", value: "apiCallsPerYear" },
                { label: "Face recognition", value: "identityPerYear" }
            ]);
        } else {
            setOptions([
                { label: "Consumption", value: "consumptionPerYear" },
                { label: "API Call", value: "apiCallsPerYear" }
            ]);
        }
        //change value in select
        setSelectOptions(options.find(({ value }) => value === optionToSelect));
        if (searchKey && servicesManagementState?.consumptionState?.data) {
            dataBySearchKey = formatCalendarData(
                servicesManagementState?.consumptionState?.data?.totalCountsPerPeriod,
                searchKey,
                typeOfCall
            );
        }
        let currentYear = [];
        if (optionToSelect === "apiCallsPerYear") {
            currentYear = dataBySearchKey
                ? dataBySearchKey?.apiCallsPerYear?.find(
                      (year: any) => year?.year?.toString() === moment().format("YYYY")
                  ) || []
                : globalState?.apiCallsPerYear?.find(
                      (year: any) => year?.year?.toString() === moment().format("YYYY")
                  ) || [];
        } else if (optionToSelect === "consumptionPerYear") {
            currentYear = dataBySearchKey
                ? dataBySearchKey?.consumptionPerYear?.find(
                      (year: any) => year?.year?.toString() === moment().format("YYYY")
                  ) || []
                : globalState?.consumptionPerYear?.find(
                      (year: any) => year?.year?.toString() === moment().format("YYYY")
                  ) || [];
        } else if (optionToSelect === "identityPerYear") {
            currentYear = dataBySearchKey
                ? dataBySearchKey?.identityPerYear?.find(
                      (year: any) => year?.year?.toString() === moment().format("YYYY")
                  ) || []
                : globalState?.identityPerYear?.find(
                      (year: any) => year?.year?.toString() === moment().format("YYYY")
                  ) || [];
        }

        //set data for elem to display
        setGlobalState({
            ...globalState,
            apiCallsPerYear: dataBySearchKey
                ? dataBySearchKey?.apiCallsPerYear
                : globalState.apiCallsPerYear,
            consumptionPerYear: dataBySearchKey
                ? dataBySearchKey?.consumptionPerYear
                : globalState.consumptionPerYear,
            identityPerYear: dataBySearchKey
                ? dataBySearchKey?.identityPerYear
                : globalState.identityPerYear,
            elemToDisplay: currentYear,
            searchKey: searchKey ? listApiServices[searchKey] : undefined
        });
    };

    const handleSelect = (option: any) => {
        setSelectOptions(option);
        let currentYear = [];
        if (option.value === "apiCallsPerYear") {
            currentYear =
                globalState?.apiCallsPerYear?.find(
                    (year: any) => year?.year?.toString() === moment().format("YYYY")
                ) || [];
        } else if (option.value === "consumptionPerYear") {
            currentYear =
                globalState?.consumptionPerYear?.find(
                    (year: any) => year?.year?.toString() === moment().format("YYYY")
                ) || [];
        } else if (option.value === "identityPerYear") {
            currentYear =
                globalState?.identityPerYear?.find(
                    (year: any) => year?.year?.toString() === moment().format("YYYY")
                ) || [];
        }
        setGlobalState({
            ...globalState,
            elemToDisplay: currentYear
        });
    };

    return (
        <customCalendarContext.Provider
            value={{
                handleChangeYear,
                setSelectOptions,
                selectOptions,
                show,
                handleShow,
                handleSelect,
                options,
                globalState
            }}
        >
            {children}
        </customCalendarContext.Provider>
    );
};

export { CustomCalendarProvider, customCalendarContext };
